import Container from '@material-ui/core/Container';
import BaseElem from 'BaseElem';
import 'Common.css';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import './EmptyPage.css';

type EmptyPageProps = {
  history?: any;
};

type EmptyPageConnectProps = {};

type EmptyPageState = {};

type EmptyPageActualProps = EmptyPageProps & EmptyPageConnectProps;

class EmptyPage extends BaseElem<EmptyPageActualProps, EmptyPageState> {
  constructor(props: EmptyPageActualProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container className="empty-container vertical">
        <img src="/homage_logo_512.png" alt="" className="empty-img" />
        <h2>Oops. Looks like you're lost</h2>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {...state};
};

const mapDispatchToProps = (dispatch: any) => ({});

export default withRouter(
  connect<EmptyPageState, EmptyPageConnectProps>(mapStateToProps, mapDispatchToProps)(EmptyPage),
);
