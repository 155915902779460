import {GET_MENU_LINKS_SUCCESS, SET_CURRENT_MENU_SELECTION, SET_MENU_COLLAPSED_STATE} from '../Constants';

type State = {
  menuItems: any;
  currentSelection?: string;
  isCollapsed: boolean;
};

type Action = {
  type: string;
  menuLinks?: any;
  menuSelection?: any;
  isCollapsed?: boolean;
};

export default (state: State = {menuItems: [], isCollapsed: true}, action: Action) => {
  switch (action.type) {
    case GET_MENU_LINKS_SUCCESS:
      return {...state, menuItems: action.menuLinks};
    case SET_CURRENT_MENU_SELECTION:
      return {...state, currentSelection: action.menuSelection};
    case SET_MENU_COLLAPSED_STATE:
      return {...state, isCollapsed: action.isCollapsed};
    default:
      return state;
  }
};
