import * as amplitude from '@amplitude/analytics-browser';
import {StylesProvider} from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress';
import EmailTemplateComposer from 'Screens/EmailTemplateComposer/EmailTemplateComposer';
import EmptyPage from 'Screens/Lost/EmptyPage';
import Cookies from 'js-cookie';
import React, {Suspense} from 'react';
import {Provider} from 'react-redux';
import {Redirect, Route, BrowserRouter as Router, Switch, useLocation} from 'react-router-dom';
import {version} from '../package.json';
import {logout} from './Actions/AuthActions';
import './App.css';
import BaseElem from './BaseElem';
import './Common.css';
import {Snackbar} from './Components/CommonComponents';
import {
  MSG_ON_PAGE_FOCUS,
  MSG_SET_LOADING_CONT_STYLE,
  MSG_SET_LOADING_STYLE,
  MSG_SHOW_SNACKBAR,
  SHOW_LOADING,
} from './Constants';
import {APP_COUNTRY} from './DataConstants';
import configureStore from './store';

const LAZY_COMPONENTS = {
  Login: React.lazy(() => import('Screens/Login/Login')),
  PasswordExpiryBanner: React.lazy(() => import('Components/PasswordExpiryBanner')),
  RedirectComponent: React.lazy(() => import('Screens/Redirect/Redirect')),
  RootDirectory: React.lazy(() => import('Screens/RootDirectory')),
  AccountDetails: React.lazy(() => import('./Screens/AccountDetails')),
  AdminSideBar: React.lazy(() => import('./Components/SideBar/AdminSideBar')),
  AdminSideBarExpander: React.lazy(() => import('./Components/SideBar/AdminSideBarExpander')),
  B2BExportPage: React.lazy(() => import('Screens/B2BExportPage')),
  CareOwnerIndex: React.lazy(() => import('Screens/CareOwner/CareOwnerIndex')),
  CPDashboard: React.lazy(() => import('Screens/CPDashboard/CPDashboard')),
  CPGSTStatus: React.lazy(() => import('Screens/CPGSTStatus')),
  CPWaivers: React.lazy(() => import('Screens/CPWaivers')),
  ManageCareRecipient: React.lazy(() => import('Screens/ManageCareRecipientB2C/ManageCareRecipient')),
  CombinedCPRosterCalendarView: React.lazy(() => import('Screens/CPRoster/CombinedCPRosterCalendarView')),
  OfflinePaymentSettings: React.lazy(() => import('Screens/OfflinePaymentSettings')),
  PaymentsDashboard: React.lazy(() => import('Screens/PaymentsDashboard')),
  PCAppActivationDashboard: React.lazy(() => import('Screens/PCAppActivationDashboard')),
  PricingEngine: React.lazy(() => import('Screens/Pricing/PricingEngineIndex')),
  PriorityInbox: React.lazy(() => import('Screens/PriorityInbox')),
  RecruitmentWorkflowList: React.lazy(() => import('Screens/RecruitmentWorkflowList/RecruitmentWorkflowList')),
  RecruitmentWorkflowSettings: React.lazy(
    () => import('Screens/RecruitmentWorkflowSettings/RecruitmentWorkflowSettings'),
  ),
  STCJobs: React.lazy(() => import('Screens/ShortTermContract/STCJobs')),
  Visits: React.lazy(() => import('Screens/Visits')),
  ServiceCoordinatorOperator: React.lazy(() => import('Screens/ServiceCoordinatorOperator')),
  CareProSignupForm: React.lazy(() => import('./External Pages/CareProSignupForm')),
  PaymentWebLink: React.lazy(() => import('./External Pages/PaymentWebLink')),
  PIQForm: React.lazy(() => import('./External Pages/PIQForm')),
  CareProProfile: React.lazy(() => import('./External Pages/CareProProfile')),
} as const;

const store = configureStore();
const ONLINE = 'online';
const OFFLINE = 'offline';

type AppState = {
  isDisconnected: boolean;
  loading: number;
  loadingStyle?: any;
  loadingContStyle?: any;
  snackbars: any[];
};

class App extends BaseElem<any, AppState> {
  _bindConChange?: any;
  _bindOnFocus?: any;
  _bindOnBlur?: any;
  _bindVisibilityChange?: any;
  _isVisible = true;
  _lastReq = 0;

  constructor(props: any) {
    super(props);
    this.state = {
      isDisconnected: false,
      loading: 0,
      snackbars: [],
    };
    this._enableListeners(true);
    document.title = 'Smiley 2.0';
  }

  _enableListeners(isEnabled: boolean) {
    const self = this,
      win = window;
    const listener = isEnabled ? 'addEventListener' : 'removeEventListener';

    if (isEnabled && !self._bindConChange) {
      win._app = self;
      self._bindConChange = self._onConnectionChange.bind(self);
      self._bindOnFocus = self._onFocus.bind(self);
      self._bindOnBlur = self._onBlur.bind(self);
      self._bindVisibilityChange = self._onVisibilityChange.bind(self);
    }

    win[listener]('focus', self._bindOnFocus);
    win[listener]('blur', self._bindOnBlur);
    document[listener]('visibilitychange', self._bindVisibilityChange);

    win[listener](ONLINE, self._bindConChange);
    win[listener](OFFLINE, self._bindConChange);
    self.setListeners(isEnabled, SHOW_LOADING, MSG_SHOW_SNACKBAR, MSG_SET_LOADING_STYLE, MSG_SET_LOADING_CONT_STYLE);
    if (process.env.REACT_APP_AMPLITUDE) {
      const API_KEY = process.env.REACT_APP_AMPLITUDE;
      amplitude.init(API_KEY);
    }
  }

  componentDidMount() {
    const self = this;
    self._onConnectionChange();
    self._enableListeners(true);
  }

  componentWillUnmount() {
    this._enableListeners(false);
  }

  _onFocus() {
    const self = this;
    self._isVisible = true;
    self.post(MSG_ON_PAGE_FOCUS);
  }

  _onBlur() {
    this.async(() => (this._isVisible = !document.hidden), 100);
  }

  _onVisibilityChange() {
    this[document.hidden ? '_onBlur' : '_onFocus']();
  }

  onMessage(messageName: string, payload?: any) {
    const self = this,
      state = self.state;
    if (messageName === SHOW_LOADING) {
      self.setState({loading: state.loading + (payload || 0)});
    } else if (messageName === MSG_SET_LOADING_STYLE) {
      self.setState({loadingStyle: payload});
    } else if (messageName === MSG_SET_LOADING_CONT_STYLE) {
      self.setState({loadingContStyle: payload});
    } else if (payload && messageName === MSG_SHOW_SNACKBAR) {
      const snackbars = state.snackbars;
      if (snackbars.find(sb => !sb.actionTitle && sb.message === payload.message)) return;
      payload.open = true;
      snackbars.push(payload);
      self.setState({snackbars: snackbars});

      const duration = payload.duration;
      duration && self.async(() => self._removeSnackbar(payload), duration);
    }
  }

  _removeSnackbar(snackbar: any) {
    const self = this,
      snackbars = self.state.snackbars;
    snackbar.open = false;
    self.setState({snackbars: snackbars});
    self.async(() => self.setState({snackbars: snackbars.filter(s => s !== snackbar)}), 500);
    return true;
  }

  _onConnectionChange() {
    this.setState({isDisconnected: !navigator.onLine});
  }

  render() {
    const self = this,
      state = self.state;
    return (
      <StylesProvider injectFirst>
        <Provider store={store}>
          <div className="app vertical">
            <Router>
              <Switch>
                /* External landing pages (Doesn't require login) */
                <Route exact path={'/piq/apply'}>
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.PIQForm />
                  </Suspense>
                </Route>
                <Route exact path={'/public-carepro-profile'}>
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.CareProProfile />
                  </Suspense>
                </Route>
                {!!(process.env.REACT_APP_CP_APPLY || !process.env.REACT_APP_DISABLE_VAL) && (
                  <Route exact path={process.env.REACT_APP_DISABLE_VAL ? ['*'] : ['/carepro/apply']}>
                    <Suspense fallback={<div />}>
                      <LAZY_COMPONENTS.CareProSignupForm />
                    </Suspense>
                  </Route>
                )}
                {!!(process.env.REACT_APP_PAYMENTS_DOMAIN || !process.env.REACT_APP_DISABLE_VAL) && (
                  <Route
                    exact
                    path={
                      process.env.REACT_APP_PAYMENTS_DOMAIN
                        ? ['/:paymentId', '/:paymentId/*']
                        : ['/weblink/:paymentId', '/weblink/:paymentId/*']
                    }>
                    <Suspense fallback={<div />}>
                      <LAZY_COMPONENTS.PaymentWebLink />
                    </Suspense>
                  </Route>
                )}
                /* End External landing pages */
                <Route exact path="/lost">
                  <NavBar />
                  <EmptyPage />
                </Route>
                <Route exact path="/login">
                  <NavBar />
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.Login></LAZY_COMPONENTS.Login>
                  </Suspense>
                </Route>
                <PrivateRoute exact path="/">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.RootDirectory />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute exact path="/account-details">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.AccountDetails />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute exact path={['/careowner', '/careowner/:id/:cr_id?']}>
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.CareOwnerIndex />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute exact path="/cr/:id/:co_id?/:service_type?">
                  <Suspense fallback={<div />}>
                    <Suspense fallback={<div />}>
                      <LAZY_COMPONENTS.ManageCareRecipient />
                    </Suspense>
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute exact path="/offline-payment-settings">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.OfflinePaymentSettings />
                  </Suspense>
                </PrivateRoute>
                <Route path="/redirect">
                  <NavBar />
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.RedirectComponent />
                  </Suspense>
                </Route>
                <PrivateRoute exact path="/cp-waivers">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.CPWaivers />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/cp-gst-status">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.CPGSTStatus />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/stc">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.STCJobs />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/pricing">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.PricingEngine />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/priority-inbox">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.PriorityInbox />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/payments-dashboard">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.PaymentsDashboard />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/b2b/nok-activation-dashboard">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.PCAppActivationDashboard />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/recruitment-workflow-list">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.RecruitmentWorkflowList />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/recruitment-workflow-settings">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.RecruitmentWorkflowSettings />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/b2b/*">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.B2BExportPage />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/individual_visits">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.Visits />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/cp-dashboard">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.CPDashboard />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/sco-management">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.ServiceCoordinatorOperator />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/standby_cp_roster">
                  <Suspense fallback={<div />}>
                    <LAZY_COMPONENTS.CombinedCPRosterCalendarView />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/compose-email-template">
                  <Suspense fallback={<div />}>
                    <EmailTemplateComposer />
                  </Suspense>
                </PrivateRoute>
                <Redirect from="/" to="/login" />
              </Switch>
            </Router>

            <LoadingSpinner loading={state.loading} style={state.loadingStyle} />

            <SnackbarContainer
              isDisconnected={state.isDisconnected}
              snackbars={state.snackbars}
              onRemove={self._removeSnackbar.bind(self)}
            />
          </div>
        </Provider>
      </StylesProvider>
    );
  }
}

const PrivateRoute: React.FC<any> = ({children, ...props}) => {
  const location = useLocation();
  const isAuthenticated = Boolean(Cookies.get('userid'));

  if (!isAuthenticated) {
    return <Redirect to={{pathname: '/login', state: {from: location}}} />;
  }

  return (
    <Route {...props}>
      <Suspense fallback={<div />}>
        <LAZY_COMPONENTS.PasswordExpiryBanner />
        <AdminNavBar />
        <div className="app-section">
          <Suspense fallback={<div />}>
            <LAZY_COMPONENTS.AdminSideBar />
          </Suspense>
          <div id="right" className="content-section">
            <div className="main-container">{children}</div>
          </div>
        </div>
      </Suspense>
    </Route>
  );
};

const logOut = async () => {
  if (await logout()) {
    Cookies.remove('userid');
    window.location.reload();
  }
};

const getEnvironment = () => {
  const apiUrl = process?.env?.REACT_APP_API_URL ?? '';
  if (apiUrl.includes('dev')) return 'dev';
  if (apiUrl.includes('testing')) return 'staging';
  return '';
};

const displayVersionAndCountryAndEnvironment = () => {
  const envString = getEnvironment();
  return envString ? ` ${version}-${envString}-${APP_COUNTRY}` : '';
};

function NavBar() {
  return (
    <div className="navigation-bar h center">
      <img className="homage-logo" src="/homage_logo_white.png" alt="" onClick={logOut} />
      <span className="homage-title">HOMAGE{displayVersionAndCountryAndEnvironment()}</span>
    </div>
  );
}

function AdminNavBar() {
  const userId = Cookies.get('userid');
  return (
    <div className="navigation-bar h center">
      {userId && (
        <Suspense fallback={<div />}>
          <LAZY_COMPONENTS.AdminSideBarExpander />
        </Suspense>
      )}
      <a className="h vc" href="/">
        <img className="homage-logo" src="/homage_logo_white.png" alt="" />
        <span className="homage-title">HOMAGE{displayVersionAndCountryAndEnvironment()}</span>
      </a>
      <div className="logout" onClick={logOut}>
        Logout
      </div>
    </div>
  );
}

const LoadingSpinner: React.FC<{loading: number; style?: any}> = ({loading, style}) => (
  <div className={'loading-spinner' + (loading <= 0 ? ' gone' : '')} style={style}>
    <div className="app-spinner-cont">
      <CircularProgress color="primary" />
    </div>
  </div>
);

const SnackbarContainer: React.FC<{
  isDisconnected: boolean;
  snackbars: any[];
  onRemove: (snackbar: any) => void;
}> = ({isDisconnected, snackbars, onRemove}) => (
  <div className="app-snackbars">
    <Snackbar noClose variant="info" isOpen={isDisconnected} message="No Internet Connection" />
    {snackbars.map(sb => (
      <Snackbar key={sb.key} {...sb} noClose={!!sb.duration} onClose={() => onRemove(sb)} />
    ))}
  </div>
);

export default App;
