import React, {createRef} from 'react';
import {_attr, clamp, fontsLoaded, positionVerticallyWithAnchor, setDocumentScrollEnabled} from 'Util/Utilities';
import BaseElem from '../BaseElem';
import '../Common.css';
import {UNDEF} from '../Constants';
import {convertToDestTZ, dateToAMPM, formatDate} from '../Util/DateUtils';
import {Div} from './GenericView';
import './InputField.css';

type InputFieldProps = {
  className?: string;
  inpClassName?: string;
  placeholder?: string;
  type: string; // types: 'date', 'time', 'text', 'number'
  date?: Date; // for type = 'date' & 'time'
  text?: string; // for type = 'text'
  hasError?: boolean; // show soft red color when true
  isBold?: boolean; // make the font bold
  disabled?: boolean; // disable the text field
  region?: string;

  onTextChange?: Function; // input change callback
  onKeyPress?: Function; // keypress callback
  onFocus?: Function; // on gain focus callback
  onBlur?: Function; // on lose focus callback
  hideFocusBar?: boolean; // don't show green bar at bottom

  min?: number; // Minimum value for type = number
  max?: number; // Maximum value for type = number
  maxLength?: number; // Maximum value for type = text

  readOnly?: boolean; // readonly input field
  updating?: boolean;
  popupDisabled?: boolean; // don't show the popup

  autoComplete?: string;
  noAutoSelect?: boolean; // don't auto select text on focus
  width?: string; // when specified disable dynamic width
  maxWidth?: string;
  inlineExpand?: boolean; // expand the props.children below instead of dropdown popup
  manualBlur?: boolean; // means can only lose focus by manually calling component.blur()

  popupWidth?: string;
  popupHeight?: string; //fixed height i.e. "100px"
  popupMaxWidth?: string; //max width "300px"
  popupMaxHeight?: string; //max height "300px"
  popupAlignTop?: boolean;

  // to disable blur on enter key press
  blurOnEnterKey?: boolean;
  blurOnTabKey?: boolean;

  hidden?: boolean; // when true, hide the input field

  // Multi-line properties
  multiLine?: boolean;
  lineHeight?: number;
  maxLines?: number;

  // disable background and size animation
  dontAnimate?: boolean;

  dontDisableDocScroll?: boolean;

  debounceDuration?: number;
  hoverColor?: string;
};

type InputFieldState = {
  _isOpen: boolean;
};

export class InputField extends BaseElem<InputFieldProps, InputFieldState> {
  _wasScrollEnabled = false;
  _bindKeyPress: any;
  _hasFocus = false;
  _debounceActive = false;
  _isChanged = false;

  _refInput = createRef<any>();
  _refLabel = createRef<any>();
  _refPopup = createRef<any>();
  _rows = 1;

  constructor(props: InputFieldProps) {
    super(props);
    this.state = {
      _isOpen: false,
    };
  }

  public isChanged() {
    return this._isChanged;
  }

  // Setup event listeners
  _enableListeners(isEnabled = false) {
    const self = this,
      doc = window.document;

    if (isEnabled && !self._bindKeyPress) {
      self._bindKeyPress = self._onKeyPress.bind(self);
    }

    const listener = isEnabled ? 'addEventListener' : 'removeEventListener';
    doc[listener]('keydown', self._bindKeyPress);

    if (self.props.children && !self.props.dontDisableDocScroll) {
      const wasScrollEnabled = self._wasScrollEnabled;
      if (isEnabled && !wasScrollEnabled) {
        self._wasScrollEnabled = setDocumentScrollEnabled(false);
      } else if (wasScrollEnabled) {
        setDocumentScrollEnabled(true);
        self._wasScrollEnabled = false;
      }
    }
  }

  componentWillUnmount() {
    this._enableListeners(false);
  }

  componentDidMount() {
    this.componentDidUpdate(this.props);
  }

  componentDidUpdate(prevProps: InputFieldProps) {
    const self = this,
      props = self.props,
      type = props.type;
    if (!self._hasFocus) {
      if (type === 'date' || type === 'time') {
        self.updateValue(props.date);
      } else if (type === 'text' || type === 'number') {
        const isChanged = props === prevProps || props.text !== prevProps.text || props.hidden !== prevProps.hidden;

        if (prevProps.text && !props.text) {
          self._refInput.current.style.width = props.width || '';
        }

        isChanged && self.updateValue(props.text);
      }
    }
  }

  focus() {
    this._refInput.current?.focus();
  }

  updateValue(val?: any) {
    const self = this,
      props = self.props,
      value = self._getValue(val);
    const inputElem = self._refInput.current as HTMLInputElement;
    if (inputElem) {
      inputElem.value = value;
      (self._refLabel.current || {}).textContent =
        (value || props.placeholder || '') + (props.multiLine && value.endsWith('\n') ? 'x' : '');
      self._updateSize(true);
    }
  }

  getText() {
    return this._refInput.current?.value;
  }

  _updateSize(animate = false) {
    const self = this,
      props = self.props,
      fixedWidth = !!props.width,
      isMultiline = !!props.multiLine,
      inpElem = self._refInput.current,
      label = self._refLabel.current,
      px = 'px';

    const inpStyle = inpElem.style;
    if (!label || !inpElem) return;

    if (!fixedWidth) {
      inpStyle.transition = animate && !props.dontAnimate ? '' : 'unset';
      const calculatedWidth = label.offsetWidth;

      calculatedWidth && (inpStyle.width = calculatedWidth + px);

      // Wait for max 1.5s for the fonts to load
      if (props.text && (!fontsLoaded() || !calculatedWidth)) {
        self._updateLabelSize(inpStyle, label, 0);
      }
    } else if (isMultiline) {
      const lineHeight = props.lineHeight || 22;
      const rows = Math.ceil(label.offsetHeight / lineHeight);
      self._rows = clamp(rows, 1, props.maxLines || 5);
      inpElem.rows = self._rows;
    }
  }

  // Wait for 1.5 seconds for the fonts to load
  _updateLabelSize(inpStyle: any, label: any, attempt = 0) {
    if (attempt > 20) return;
    const width = label.offsetWidth;
    width && (inpStyle.width = width + 'px');
    (!fontsLoaded() || !width) && setTimeout(() => this._updateLabelSize(inpStyle, label, attempt + 1), 75);
  }

  _getValue = (val: any): string => {
    const type = this.props.type;
    if (type === 'date') {
      return this.props.text || formatDate(val, true); //.substr(4);
    } else if (type === 'time') {
      // handling create and update visit with these condition
      return this.props.updating || this.props.readOnly
        ? dateToAMPM(convertToDestTZ(val, this.props.region))
        : dateToAMPM(val);
    } else {
      // text
      return (val || '') as string;
    }
  };

  _onChange = (e: any) => {
    const self = this,
      target = e.currentTarget,
      value = target.value;
    (self._refLabel.current || ({} as HTMLElement)).textContent =
      value + (self.props.multiLine && value.endsWith('\n') ? 'x' : '');
    self._updateSize();
    self._isChanged = true;
    self._debounceActive = true;

    self.debounce(() => {
      if (!self._debounceActive) return;
      self._debounceActive = false;
      self._invokeChange(target);
    }, self.props.debounceDuration || 200);
  };

  _invokeChange(target: any) {
    const fn = this.props.onTextChange;
    fn && fn(this, target.value);
  }

  _onFocus = (_e: any) => {
    const self = this;
    const props = self.props;
    if (self._hasFocus || self.state._isOpen) {
      self._hasFocus = true;
      return;
    }
    self._hasFocus = true;
    self._isChanged = false;
    self.setState({_isOpen: true});
    const fn = props.onFocus;
    fn && fn(self);

    //const anchor = e.currentTarget;

    // Need to skip a frame
    self.async(() => {
      self._enableListeners(true);
      self.positionPopup();
    });

    !props.readOnly && !props.noAutoSelect && self.async(() => self._refInput.current?.select(), 50);
  };

  positionPopup() {
    const self = this,
      props = self.props;
    const anchor = self._refInput.current;
    if (anchor && self.props.children && !props.popupDisabled) {
      const popup = self._refPopup.current as HTMLElement;
      const alignWithTop = props.popupAlignTop;
      const yOffset = alignWithTop ? 0 : 4;
      popup &&
        self.async(() => positionVerticallyWithAnchor(popup, anchor.getBoundingClientRect(), 0, yOffset, alignWithTop));
    }
  }

  _onBlur = (_e: any) => {
    const self = this,
      props = self.props;
    self._hasFocus = false;
    if (props.popupDisabled || (!props.children && !props.manualBlur)) {
      self.blur();
    }
  };

  blur = () => {
    const self = this;
    self._hasFocus = false;

    const input = self._refInput.current;
    input && self._debounceActive && self._invokeChange(input.value);
    self._debounceActive = false;

    input && input.blur();

    self.setState({
      _isOpen: false,
    });

    self._enableListeners(false);

    self.props.onBlur && self.props.onBlur(self);

    const popupStyle = self._refPopup.current?.style || {};
    popupStyle.opacity = 0;
    popupStyle.transform = '';
  };

  _onKeyPress(e: any) {
    const self = this,
      props = self.props,
      key = e.key;

    key === 'Escape' && self.blur();

    if (
      (props.blurOnTabKey !== false && key === 'Tab') ||
      (!props.multiLine && props.blurOnEnterKey && key === 'Enter')
    ) {
      self.blur();
    }

    const fn = props.onKeyPress;
    fn && fn(self, key, e);
  }

  clearValue() {
    (this._refInput.current || {}).value = '';
  }

  _getPopupWidth(widthFromProps: any) {
    if (!widthFromProps) return UNDEF;
    if (widthFromProps === '100%') {
      const inp = this._refInput.current;
      return !inp ? UNDEF : inp.offsetWidth;
    }
    return widthFromProps;
  }

  render() {
    const self = this,
      isPopupOpen = self.state._isOpen,
      props = self.props,
      isMultiLine = props.multiLine;

    return (
      <Div
        className={`input-field ${props.className || ''}${props.hidden ? ' gone' : ''}`}
        isSelected={isPopupOpen}
        width={props.width}>
        {/* This is to compute size of the text field */}
        <div
          ref={self._refLabel}
          className="if-input if-measure-label"
          style={{
            margin: 0,
            position: isMultiLine ? 'absolute' : 'fixed',
            left: isMultiLine ? '' : 0,
            marginLeft: isMultiLine ? '8px' : UNDEF,
            marginRight: isMultiLine ? '8px' : UNDEF,
            maxHeight: (props.lineHeight || 22) * (props.maxLines || 30) + 'px', //22 * 5
            overflowY: 'auto',
            pointerEvents: 'none',
            visibility: 'hidden',
            lineHeight: props.lineHeight ? props.lineHeight + 'px' : 'inherit',
            fontWeight: props.isBold ? 'bold' : 'inherit',
          }}
        />

        {isMultiLine ? (
          <textarea
            ref={self._refInput}
            className={`if-input if-input-field if-type-${self.props.type} ${props.inpClassName || ''}`}
            style={{
              display: 'block',
              lineHeight: 'inherit',
              fontWeight: props.isBold ? 'bold' : 'inherit',
              width: props.width,
              maxWidth: props.maxWidth,
              boxSizing: props.width ? 'border-box' : UNDEF,
            }}
            has-error={_attr(self.props.hasError)}
            has-focus={_attr(isPopupOpen)}
            onChange={self._onChange}
            onFocus={self._onFocus}
            onBlur={self._onBlur}
            readOnly={props.readOnly}
            disabled={props.disabled}
            rows={self._rows}
            placeholder={props.placeholder}
          />
        ) : (
          <input
            ref={self._refInput}
            className={`if-input if-input-field if-type-${props.type} ${props.inpClassName || ''}`}
            style={{
              width: props.width,
              fontWeight: props.isBold ? 'bold' : 'inherit',
              maxWidth: props.maxWidth,
              boxSizing: props.width ? 'border-box' : UNDEF,
            }}
            autoComplete={props.autoComplete || 's'}
            type={props.type === 'number' ? 'number' : 'text'}
            min={props.min}
            max={props.max}
            maxLength={props.maxLength}
            has-error={_attr(props.hasError)}
            has-focus={_attr(isPopupOpen)}
            onChange={self._onChange}
            onFocus={self._onFocus}
            onBlur={self._onBlur}
            readOnly={props.readOnly}
            disabled={props.disabled}
            placeholder={props.placeholder}
          />
        )}

        <div
          className="if-bottom-bar"
          style={props.hideFocusBar || (props.readOnly && props.hideFocusBar === UNDEF) ? displayNone : displayEmpty}
        />

        <div
          className="if-fixed-overlay"
          style={isPopupOpen && self.props.children && !self.props.popupDisabled ? displayBlock : displayNone}
          onClick={self.blur}>
          <div
            ref={self._refPopup}
            onClick={self.eatClick}
            className={`if-popup if-popup-${self.props.type}`}
            style={{
              width: self._getPopupWidth(props.popupWidth),
              height: props.popupHeight,
              maxWidth: props.popupMaxWidth,
              maxHeight: props.popupMaxHeight,
            }}
            has-focus={_attr(isPopupOpen)}>
            {self.props.children}
          </div>
        </div>
      </Div>
    );
  }
}

const displayBlock = {display: 'block'};
const displayNone = {display: 'none'};
const displayEmpty = {display: ''};
