import {Dropdown} from '../Util/Enum';

export const CustomSelectReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'DROPDOWN_TOGGLE':
      if (!state.othersText && state.othersCheckbox) return {...state, isOpen: action.payload, othersCheckbox: false};
      return {...state, isOpen: action.payload};
    case Dropdown.SELECT:
      return {...state, selectedItem: action.payload};
    case Dropdown.OTHER_SELECT:
      return {...state, othersCheckbox: action.payload};
    case Dropdown.OTHER_TEXT:
      return {...state, othersText: action.payload};
    case Dropdown.RESET_SELECT:
      return selectInitialState;
    case Dropdown.RESET_MULTI_SELECT:
      return multiSelectInitialState;
    default:
      return selectInitialState;
  }
};

export const selectInitialState = {
  othersCheckbox: false,
  othersText: '',
  selectedItem: '',
  isOpen: false,
};
export const multiSelectInitialState = {
  othersCheckbox: false,
  othersText: '',
  selectedItem: [],
  isOpen: false,
};
