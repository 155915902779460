import {combineReducers, Reducer} from 'redux';
import recipientStore from './CrReducer';
import visitDraftStore from './VisitDraftReducer';
import visitStore from './VisitReducer';
import pricingStore from './PricingReducer';
import sideMenuStoreReducer from './SideMenuReducer';
import {CustomSelectReducer} from './CustomSelectReducer';
import careOwnerReducer from 'Screens/CareOwner/reducer';

// All reducers combined

export interface ApplicationState {
  careOwnerStore: any;
  customSelectStore: any;
  pricingStore: any;
  recipientStore: any;
  sideMenuStore: any;
  visitDraftStore: any;
  visitStore: any;
}

export const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  careOwnerStore: careOwnerReducer,
  customSelectStore: CustomSelectReducer,
  pricingStore,
  recipientStore,
  sideMenuStore: sideMenuStoreReducer,
  visitDraftStore,
  visitStore,
});
