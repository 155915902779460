import {CareProType} from 'Components/CareProSearchDropdown/types';
import {
  CLEAR_PREFERRED_CARE_PROS_LIST,
  GET_CARE_OWNER_FAILED,
  GET_CARE_OWNER_REQUEST,
  GET_CARE_OWNER_SUCCESS,
  GET_PREFERRED_CARE_PROS_FAILED,
  GET_PREFERRED_CARE_PROS_REQUEST,
  GET_PREFERRED_CARE_PROS_SUCCESS,
  GET_SELF_SERVE_OPTIONS_FAILED,
  GET_SELF_SERVE_OPTIONS_REQUEST,
  GET_SELF_SERVE_OPTIONS_SUCCESS,
  SET_CURRENT_SELECTED_RECIPIENT,
} from 'Constants';

type State = {
  careOwner: object;
  careRecipient: Array<object>;
  currentRecipientID: string | null;
  currentRecipientPreferredCarePros: Array<CareProType>;
  selfServeOptions: object;
  loading: boolean;
};

type Action = {
  type: string;
  care_owner?: object;
  care_recipient?: Array<object>;
  cr_id?: string | null;
  response?: object;
};

const initialState = {
  careOwner: {},
  careRecipient: [],
  currentRecipientID: null,
  currentRecipientPreferredCarePros: [],
  selfServeOptions: {},
  loading: false,
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case GET_CARE_OWNER_REQUEST:
      return {...state, loading: true};
    case GET_CARE_OWNER_SUCCESS:
      return {...state, careOwner: action.care_owner, careRecipient: action.care_recipient, loading: false};
    case GET_CARE_OWNER_FAILED:
      return {...state, loading: false};
    case SET_CURRENT_SELECTED_RECIPIENT:
      return {...state, currentRecipientID: action.cr_id};
    case GET_SELF_SERVE_OPTIONS_REQUEST:
      return {...state, loading: true};
    case GET_SELF_SERVE_OPTIONS_SUCCESS:
      return {...state, selfServeOptions: action.response, loading: false};
    case GET_SELF_SERVE_OPTIONS_FAILED:
      return {...state, loading: false};
    case GET_PREFERRED_CARE_PROS_REQUEST:
      return {...state, loading: true};
    case GET_PREFERRED_CARE_PROS_SUCCESS:
      return {...state, currentRecipientPreferredCarePros: action.response, loading: false};
    case GET_PREFERRED_CARE_PROS_FAILED:
      return {...state, loading: false};
    case CLEAR_PREFERRED_CARE_PROS_LIST:
      return {...state, currentRecipientPreferredCarePros: []};
    default:
      return state;
  }
};
