import {Button, IconButton, Snackbar as RSnackbar, SnackbarContent} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {CheckCircle, Close, Error, Warning} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {eatClick, guessMinutes, loadScript, setDocumentScrollEnabled} from 'Util/Utilities';
import '../Common.css';
import {UNDEF} from '../Constants';
import {minsToAMPM, minsToMilitary} from '../Util/DateUtils';
import './CommonComponents.css';
import {TextDropdown} from './TextDropdown';

export const LoadingSpinner = () => (
  <div className="loading-spinner">
    <CircularProgress color="primary" />
  </div>
);

const variantIcon: any = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
};

const SnackbarContentStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: '#32BDC7',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: '#e6f1f2',
    color: '#4a4a4a',
  },
  warning: {
    backgroundColor: '#FFBC49',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-line',
  },
  button: {
    color: '#fff',
    fontSize: 13,
    fontWeight: 500,
    marginRight: -8,
    // border: '1px solid #ececec'
  },
}));

function SnackBarContentWrapper(props: any) {
  const classes: any = SnackbarContentStyles();
  const {className, message, onAction, variant, actionTitle, onClose, noClose, ...other} = props;
  const Icon = variantIcon[variant];
  let action: any[] = [];

  onAction &&
    action.push(
      <Button
        key="action"
        color="primary"
        size="small"
        className={classes.button}
        onClick={() => {
          onAction();
          onClose();
        }}>
        {actionTitle}
      </Button>,
    );

  !noClose &&
    onClose &&
    action.push(
      <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
        <Close className={classes.icon} />
      </IconButton>,
    );

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {Icon ? <Icon className={clsx(classes.icon, classes.iconVariant)} /> : null}
          {message}
        </span>
      }
      action={action}
      {...other}
    />
  );
}

const SnackbarStyles = makeStyles((theme: Theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export function Snackbar(props: any) {
  const classes = SnackbarStyles();
  const {className, message, isOpen, variant, actionTitle, onAction, onClose, noClose, ...other} = props;

  return (
    <RSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={1000}
      {...other}>
      <SnackBarContentWrapper
        variant={variant || 'error'}
        className={classes.margin}
        message={message}
        onAction={onAction}
        actionTitle={actionTitle}
        noClose={noClose}
        onClose={onClose}
      />
    </RSnackbar>
  );
}

type PureCompProps = {
  watchList?: any[];
};
type PureCompState = {};

export class PureComp extends React.Component<PureCompProps, PureCompState> {
  shouldComponentUpdate(nextProps: PureCompProps, _nextState: PureCompState) {
    const self = this,
      props = self.props;
    const oldWatchList = props.watchList || [],
      newWatchList = nextProps.watchList || [];
    for (let k = oldWatchList.length; k--; ) {
      if (oldWatchList[k] !== newWatchList[k]) return true;
    }
    return false;
  }

  render() {
    return this.props.children;
  }
}

type PortalDialogProps = {
  // css class name & style
  className?: string;
  style?: any;

  // disable outside scroll
  disableScroll?: boolean;

  result?: (btn: number) => void;

  hideOverlay?: boolean;

  // dialog children
  children?: any;
};
export function PortalDialog(props: PortalDialogProps) {
  const [rootElem] = useState(document.createElement('div'));
  const disableScroll = !!props.disableScroll;

  useLayoutEffect(() => {
    document.body.appendChild(rootElem);
    disableScroll && setDocumentScrollEnabled(false);

    return () => {
      document.body.removeChild(rootElem);
      disableScroll && setDocumentScrollEnabled(true);
    };
  }, [rootElem, disableScroll]);

  return ReactDOM.createPortal(
    <div
      className={'ha-dialog-bg' + (props.hideOverlay ? ' ha-dialog-no-bg' : '')}
      onClick={(e: any) => eatClick(e) && props.result && props.result(0)}>
      <div
        className={props.className || 'ha-dialog ha-dialog-appear vertical'}
        onClick={e => e.stopPropagation()}
        style={props.style}>
        {props.children}
      </div>
    </div>,
    rootElem,
  );
}

export function OkCancelDialog(props: any) {
  const callback = props.callback;
  const result = (res: number) => callback && callback(res);

  return (
    <PortalDialog result={result} style={props.style}>
      <span className="ha-dialog-title">{props.title}</span>
      <span className="ha-dialog-body">{props.message}</span>
      <div className="horizontal end-justified">
        {props.cancelText && (
          <Button size="small" className="ha-dialog-cancel" onClick={() => result(1)}>
            {props.cancelText}
          </Button>
        )}

        <Button size="small" className="ha-dialog-done" onClick={() => result(2)}>
          {props.okText || 'OK'}
        </Button>
      </div>
    </PortalDialog>
  );
}

/* incomplete */
export function InputTextDialog(props: any) {
  const callback = props.callback;
  const result = (res: number) => callback && callback(res);

  return (
    <PortalDialog result={result} style={props.style}>
      <span className="ha-dialog-title">{props.title}</span>
      <textarea value={props.value}></textarea>
      <div className="horizontal justified">
        {props.applyAll && (
          <Button size="small" className="li-done-all" onClick={() => result(1)}>
            {props.applyAll}
          </Button>
        )}

        <Button size="small" className="ha-dialog-done" onClick={() => result(2)}>
          {props.okText || 'OK'}
        </Button>
      </div>
    </PortalDialog>
  );
}

// Time display: am/pm format
// Input/Output: Military time format: '14:00'
export function TimePicker(props: any) {
  const list = props.timeEntries || [];
  const ampmOutput = props.ampmOutput;

  const guessTime = (value: string) => {
    const minutes = guessMinutes(value);
    if (minutes === UNDEF) return;
    const entry = list.find((v: any) => v.mins === minutes);
    return entry || {mins: minutes, name: minsToAMPM(minutes, false)};
  };

  const toMilitary = (mins: number) => {
    return mins !== UNDEF && minsToMilitary(mins);
  };
  const minsFromMilitaryTime = (time: string) => {
    if (!time) return;
    if (ampmOutput) return time;

    const arr = time.split(':');
    const minutes = parseInt(arr[0]) * 60 + parseInt(arr[1]);
    //const entry = list.find((v:any) => v.mins === minutes);
    //return entry || {mins: minutes, name: minsToAMPM(minutes, false)};
    return minsToAMPM(minutes, false);
  };

  return (
    <TextDropdown
      className={props.className}
      style={props.style}
      width={props.width}
      lineHeight={props.lineHeight}
      inputClassName={props.inputClassName}
      popupMaxHeight="200px"
      placeholder={props.placeholder}
      popupDisabled={props.popupDisabled}
      readOnly={props.readOnly}
      hideFocusBar={props.hideFocusBar}
      dontDisableDocScroll={props.dontDisableDocScroll}
      titleProperty="name"
      idProperty="mins"
      showDropdownArrow={props.showDropdownArrow}
      matchFn={guessTime}
      items={list}
      selectedItem={minsFromMilitaryTime(props.selectedItem)}
      onChange={(_: any, item: any) => props.onChange(!item ? UNDEF : ampmOutput ? item.name : toMilitary(item.mins))}
    />
  );
}

type GoogleAutocompleteProps = {
  inputRef: any;
  children?: any;
  onPlaceChange: (place: any) => void;
};
export const GoogleAutocomplete: React.FC<GoogleAutocompleteProps> = ({children, inputRef, onPlaceChange}) => {
  const autoComplete = useRef(null);
  const loadingState = useRef(false);

  useEffect(() => {
    const field = inputRef.current;
    const listener = 'focus';
    const win: any = window;

    if (!field) return;

    function onScriptLoad(inputField: HTMLInputElement) {
      if (autoComplete.current) return;
      const autoComp = new win.google.maps.places.Autocomplete(inputField, {
        componentRestrictions: {
          country: [(process.env.REACT_APP_REGION || '').replace('_LOCAL', '')],
        },
      });

      autoComplete.current = autoComp;

      autoComp.setFields(['address_components', 'name']);
      autoComp.addListener('place_changed', function () {
        const place = autoComp.getPlace();
        const adr = place.address_components;
        adr && onPlaceChange(adr);
      });
    }

    function onFocus() {
      if (!loadingState.current) {
        loadingState.current = true;
        if (!win.google) {
          loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GEOCODING_KEY}&libraries=places&callback=Function.prototype`,
            () => onScriptLoad(field),
          );
        } else {
          onScriptLoad(field);
        }
      }
    }

    field.addEventListener(listener, onFocus);

    return function cleanup() {
      field.removeEventListener(listener, onFocus);
    };
  });

  return children;
};
