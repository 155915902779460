export const ADDED_VISITS = 'visits';
export const CUSTOMER_REQUEST_DATE = 'customer_request_date';
export const INITIATED_BY = 'initiated_by';
export const VISIT_IDS = 'visit_ids';
export const REASON_FOR_CHANGE = 'reason_for_change';
export const IS_PRICING_UPDATE = '_isPricingUpdate';
export const IS_FROM_DEEPLINK = '_isFromDeeplink';
export const REASON_FOR_CHANGE_DETAILS = 'reason_for_change_details';

// Cancel visit(s) properties
export const REASON_FOR_CANCELLATION = 'reason_for_cancellation';
export const SECONDARY_REASON_FOR_CANCELLATION = 'secondary_reason_for_cancellation';
export const REASON_FOR_CANCELLATION_DETAILS = 'reason_for_cancellation_details';
export const WAIVE_FEE = 'waive_fee';
export const WAIVE_FEE_REASON = 'waive_fee_reason';
export const WAIVE_FEE_REASON_DETAILS = 'waive_fee_reason_details';
export const CP_COMPENSATION = 'cp_compensation';
export const DO_NOT_PAY_CP = 'do_not_pay_cp';
export const DO_NOT_PAY_CP_REASON = 'do_not_pay_cp_reason';
export const DO_NOT_PAY_CP_DETAILS = 'do_not_pay_cp_reason_details';
export const PAY_TRANSPORT_FEE = 'pay_cp_transport_fee';
