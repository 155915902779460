import _ from 'lodash';
import {postMessage} from 'Util/Utilities';
import {
  APPLY_PRICING,
  APPLY_PRICING_START,
  GENERATE_PRICING_DRAFT,
  GENERATE_PRICING_DRAFT_LOADING,
  MSG_AFTER_PRICING_GENERATED,
  RESET_PRICING,
  RESET_PRICING_ORIGINAL_STATE,
  UNDEF,
} from '../Constants';

interface IPricingPackage {
  [key: string]: any;
}

type Action = {
  type: string;
  pricingPackages: any;
  selectedVisitId?: string;
  packageDraftId?: string;
  visitDrafts: any[];
  pricingItems: any;
  transportSurcharge: any;
  urgentSurcharge: any;
  waiveTransport: boolean;
  waiveTransportReason: string;
  otherTransportWaiveReason: string;
  waiveUrgent: boolean;
  waiveUrgentReason: string;
  otherUrgentWaiveReason: string;
  selectedDraftIds?: any[];
  previousPricingPackages?: any;
  firstVisitPricingPackages?: any;
  visitFeeBreakdown?: any;
  paymentInstructions?: string;
};

type State = {
  pricingPackages: IPricingPackage;
  firstVisitPricingPackages?: IPricingPackage;
  selectedVisitId?: string;
  pricingApplicationInProgress: boolean;
  generatePricing?: any[];
  selectedDraftIds?: any[];
  appliedPricingIds?: any[];
  firstPricingLoading: boolean;
  pricingGroup: number;
};

export default (
  state: State = {
    pricingPackages: [],
    firstVisitPricingPackages: [],
    pricingApplicationInProgress: false,
    firstPricingLoading: true,
    pricingGroup: 0,
  },
  action: Action,
) => {
  switch (action.type) {
    case GENERATE_PRICING_DRAFT_LOADING:
      return {...state, pricingLoading: true};
    case GENERATE_PRICING_DRAFT:
      state.pricingPackages = action.pricingPackages;
      state.selectedDraftIds = action.selectedDraftIds;
      postMessage(MSG_AFTER_PRICING_GENERATED, UNDEF, 1);
      return {...state, pricingLoading: false};
    case APPLY_PRICING_START:
      return {...state, pricingApplicationInProgress: true};
    case APPLY_PRICING:
      // Be careful when changing this. Being used by Update visit flow as well
      let {generatePricing = []} = state;
      let appliedPricingIds: any[] = [];
      let generatedVisitIds = _.map(generatePricing, (generated: any) => generated.visitDraft._id);
      const groupedVisitBreakdown = _.groupBy(action.visitFeeBreakdown, '_id');
      _.forEach(action.visitDrafts, (draft: any) => {
        if (!generatedVisitIds.includes(draft._id)) {
          generatePricing.push({
            visitDraft: draft,
            pricingItems: action.pricingItems,
            transportSurcharge: action.transportSurcharge,
            urgentSurcharge: action.urgentSurcharge,
            waiveTransport: action.waiveTransport,
            waiveTransportReason: action.waiveTransportReason,
            otherTransportWaiveReason: action.otherTransportWaiveReason,
            waiveUrgent: action.waiveUrgent,
            waiveUrgentReason: action.waiveUrgentReason,
            otherUrgentWaiveReason: action.otherUrgentWaiveReason,
            paymentInstructions: action.paymentInstructions,
            visitFeeBreakdown: _.first(groupedVisitBreakdown[draft._id]),
            pricingGroup: state.pricingGroup + 1,
          });
          appliedPricingIds.push(draft._id);
        }
      });
      state.pricingGroup++;
      state.pricingPackages = [];
      state.selectedDraftIds = [];
      return {
        ...state,
        generatePricing,
        pricingApplicationInProgress: false,
        appliedPricingIds,
      };
    case RESET_PRICING:
      state.generatePricing = [];
      state.pricingPackages = [];
      state.appliedPricingIds = [];
      state.selectedDraftIds = [];
      state.pricingGroup = 0;
      return {...state};
    case RESET_PRICING_ORIGINAL_STATE:
      state.generatePricing = [];
      state.pricingPackages = [];
      state.selectedDraftIds = [];
      state.appliedPricingIds = [];
      state.pricingGroup = 0;
      return {...state};
    default:
      return state;
  }
};
