import axios, {AxiosResponse} from 'axios';
import Cookies from 'js-cookie';
import {getCenterOf, postMessage} from 'Util/Utilities';
import {MSG_SET_LOADING_CONT_STYLE, MSG_SET_LOADING_STYLE, MSG_SHOW_SNACKBAR, SHOW_LOADING, UNDEF} from './Constants';

export function GET(url: string) {
  const path = url[0] === '/' ? url : process.env.REACT_APP_API_URL + '/' + url;
  return axios.get(path, {withCredentials: true});
}
export function POST(url: string, params: any) {
  const path = url[0] === '/' ? url : process.env.REACT_APP_API_URL + '/' + url;
  return axios.post(path, params, {withCredentials: true});
}

export function PUT(url: string, data: any, headers?: any) {
  const path = url[0] === '/' || url.startsWith('http') ? url : process.env.REACT_APP_API_URL + '/' + url;
  const reqHeaders = headers; // || { Authorization: `Bearer ${getAccessToken()}` };
  return axios.put(path, data, {headers: reqHeaders});
}

export type ResponseCallback = (response?: AxiosResponse<any>, error?: any) => void;

export function showLoading(show = true, style: any = null) {
  postMessage(SHOW_LOADING, show ? 1 : -1, 1);
  style !== null && postMessage(MSG_SET_LOADING_CONT_STYLE, style);
  return true;
}

export function showSnackbar(
  msg: string,
  variant: string,
  durationMilli?: number | undefined,
  actionTitle?: string,
  onAction?: any,
) {
  postMessage(MSG_SHOW_SNACKBAR, {
    key: new Date().getTime(),
    variant: variant,
    message: msg,
    duration: durationMilli,
    actionTitle: actionTitle,
    onAction: onAction,
  });
}

export default class Api {
  private static call(
    method: string,
    url: string,
    params?: any,
    respCallback?: ResponseCallback,
    showLoader: any = true,
    showErrorSnackbar = true,
    showRetry = false,
    headers?: any,
  ) {
    if (showLoader) {
      if (showLoader instanceof HTMLElement) {
        //position loading indicator in center of html element
        const center = getCenterOf(showLoader);
        postMessage(MSG_SET_LOADING_CONT_STYLE, {
          position: 'absolute',
          left: center.x - 30,
          top: center.y - 31,
        });
        postMessage(MSG_SET_LOADING_STYLE, {
          marginLeft: 0,
          marginTop: 0,
        });
      } else {
        postMessage(MSG_SET_LOADING_CONT_STYLE, {});
        postMessage(MSG_SET_LOADING_STYLE, {});
      }
      showLoading();
    }

    const request = method === 'GET' ? GET(url) : method === 'POST' ? POST(url, params) : PUT(url, params, headers);
    request.then(
      res => {
        showLoader && showLoading(false);
        respCallback && respCallback(res);

        const data = res && res.data;
        const success = data && data.success;
        const message = data && data.message;

        console.log('API Response:', {
          url,
          raw: res,
          data,
          success,
          message,
        });

        if (success === false && showErrorSnackbar) {
          const actionBtnTxt = showRetry ? 'Retry' : UNDEF;
          const callback = !actionBtnTxt
            ? UNDEF
            : () => {
                Api.call(method, url, params, respCallback, showLoader, showErrorSnackbar, showRetry, headers);
              };
          showSnackbar(message, 'warning', UNDEF, actionBtnTxt, callback);
        }
      },
      err => {
        showLoader && showLoading(false);
        respCallback && respCallback(UNDEF, err);

        if (showErrorSnackbar) {
          const resp = err.response;

          // Don't show network errors (have another snackabr for that)
          if (!resp) return;

          let data = resp && resp.data;
          // handle double data
          if (data && data.data) data = data.data;
          const errMsg = data ? data.error_reason || data.error || data.error_message || data.message : err.message;
          const error = errMsg + '\n/' + url;

          const isAuthProblem = resp && resp.status === 401;
          const relog =
            isAuthProblem &&
            (() => {
              Cookies.remove('userid');
              window.location.reload();
            });
          const actionBtnTxt = isAuthProblem ? 'Re-Login' : showRetry ? 'Retry' : UNDEF;
          const callback = !actionBtnTxt
            ? UNDEF
            : isAuthProblem
            ? relog
            : () => {
                Api.call(method, url, params, respCallback, showLoader, showErrorSnackbar, showRetry, headers);
              };
          showSnackbar(error, 'error', UNDEF, actionBtnTxt, callback);
        }
      },
    );
  }

  static GET(
    url: string,
    respCallback?: ResponseCallback,
    showLoading: any = true,
    showErrorSnackbar = true,
    showRetry = false,
  ) {
    Api.call('GET', url, UNDEF, respCallback, showLoading, showErrorSnackbar, showRetry);
  }

  static POST(
    url: string,
    params: any,
    respCallback?: ResponseCallback,
    showLoading: any = true,
    showErrorSnackbar = true,
    showRetry = false,
  ) {
    Api.call('POST', url, params, respCallback, showLoading, showErrorSnackbar, showRetry);
  }

  static PUT(
    url: string,
    data: any,
    respCallback?: ResponseCallback,
    showLoading: any = true,
    showErrorSnackbar = true,
    showRetry = false,
    headers?: any,
  ) {
    Api.call('PUT', url, data, respCallback, showLoading, showErrorSnackbar, showRetry, headers);
  }
}
